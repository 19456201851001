<template>
  <div dir="ltr" class="customize__index text-left DashboardMain" >

    <router-view></router-view>
  </div>
</template>

<script>

export default {
components:{
     
  },

}
</script>

<style scoped>
</style>